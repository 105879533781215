import React, { ReactElement, useEffect } from 'react';
import Image from 'gatsby-image';
import { Col, Container, Row } from 'react-bootstrap';
import Dock from 'react-dock';
import { Link } from 'gatsby';
import AdSense from 'react-adsense';

type DockPosition = 'right' | 'bottom';
type SearchResult = {
  id: string,
  title: string,
  description: string,
  link: string,
  category: string[],
  pubDate: string | Date,
  source?: string,
  sourceWebsite?: string
};

type Post = {
  title: string,
  body: string,
  mainImage: any,
  slug: string,
  createdAt: string,
  rssFeedLinks: string,
  researchGateLinks: string,
  show: boolean,
  onHide: () => void
};

export const MapArticle: React.FC<Post> = (post) => {
  let isWindowNarrow = false;
  if (typeof window !== `undefined`) {
    isWindowNarrow = window.innerWidth < 424;
  }
  const position: DockPosition = isWindowNarrow ? 'bottom' : 'right';
  const renderArticles = (records: SearchResult[], headingName: string): ReactElement => {
    if (records.length === 1 && records[0].link === undefined) {
      return null;
    }
    return (
      <Row className={'padding-small'}>
        <Col>
          <h5>{headingName}</h5>
          {records.filter((record, idx) => {
            if (!record.link || record.link.indexOf('researchgate.net') === -1) {
              return true;
            }
            const recordsFound = records.map(({ link }, idx) => {
              return { link, idx };
            }).filter((recordToCompareAgainst) => {
              let linkWithNoQueryParam = record.link.substr(0, record.link.indexOf('?'));
              let linkToCompareAgainst = recordToCompareAgainst.link.substr(0, recordToCompareAgainst.link.indexOf('?'));
              return linkWithNoQueryParam === linkToCompareAgainst;
            }).map(({ idx }) => idx);
            return recordsFound.length == 1 || recordsFound.length > 1 && recordsFound.slice(-1)[0] === idx;
          }).map(record => {
            const source = record.source ? `${record.source} •` : '';
            return (
              <>
                <Row>
                  <Col>
                    <Link to={record.link} target={'_blank'}>
                      {record.title}
                    </Link>
                  </Col>
                </Row>
                <Row className={'text-small'}>
                  <Col>
                    <span className="text-secondary">
                      {source} {(new Date(record.pubDate)).toDateString()}
                    </span>
                  </Col>
                </Row>
              </>
            )
          })}
        </Col>
      </Row>
    )
  };
  const articles = renderArticles(JSON.parse(post.rssFeedLinks), 'Recent News Articles');
  const researchGateDocs = renderArticles(JSON.parse(post.researchGateLinks), 'Research Papers');
  return (
    <Dock position={position} isVisible={post.show} onVisibleChange={post.onHide} size={isWindowNarrow ? 0.6 : 0.4}>
      <Container fluid={true}>
        <Row>
          <Col>
            {!isWindowNarrow && <h2>{post.title}</h2>}
            {isWindowNarrow && <h3>{post.title}</h3>}
          </Col>
        </Row>
        <Row>
          <Col>
            {post?.mainImage?.fluid && <Image
              fluid={post?.mainImage?.fluid}
              alt={post.title}
              key={`image-${post.slug}`}
            />}
          </Col>
        </Row>
        <Row>
          <Col>
            <span dangerouslySetInnerHTML={{ __html: post.body }} />
          </Col>
        </Row>
        {articles}
        <Row>
          <Col>
            <AdSense.Google
              client='ca-pub-6101154553358582'
              slot='2121278992'
              style={{ display: 'block', minWidth: '240px', maxWidth: '970px', width: '100%', height: '90px' }}
              format=''
            />
          </Col>
        </Row>
        {researchGateDocs}
        <Row>
          <Col>
            <AdSense.Google
              client='ca-pub-6101154553358582'
              slot='5287671848'
              style={{ display: 'block', minWidth: '240px', maxWidth: '970px', width: '100%', height: '90px' }}
              format=''
            />
          </Col>
        </Row>
      </Container>
    </Dock >
  );
};