import React, { ReactElement, useEffect, useState } from 'react';
import * as maptalks from 'maptalks';
import { MapArticle } from './map-article';
import './disaster-zone.css';

export type ZoneMap = {
  edges: [
    {
      node: {
        title: string;
        slug: string;
        createdAt: string;
        location: {
          lon: number;
          lat: number;
        };
        mainImage: any;
        post: {
          childMarkdownRemark: {
            html: string;
          };
        };
        rssFeedLinks: { rssFeedLinks: string }
        researchGateLinks: { researchGateLinks: string }
      };
    }
  ];
};

const mapId = 'disaster-map';
type Props = {
  posts: ZoneMap
};

export const DisasterZone: React.FC<Props> = (props) => {
  const [activeArticle, setActiveArticle] = useState<ReactElement>(null);

  const getArticleFromSlug = (slug: string): ReactElement => {
    const node = props.posts.edges
      .filter(({ node }) => node.slug && node.slug === slug)
      .map((edge) => edge.node)[0];
    return (<MapArticle body={node.post.childMarkdownRemark.html}
      show={true}
      onHide={() => setActiveArticle(null)}
      createdAt={node.createdAt}
      mainImage={node.mainImage}
      slug={node.slug}
      title={node.title}
      key={node.slug}
      rssFeedLinks={node.rssFeedLinks.rssFeedLinks}
      researchGateLinks={node.researchGateLinks.researchGateLinks} />);
  };

  useEffect(() => {
    const map = new maptalks.Map(mapId, {
      center: [0, 0],
      zoom: 3,
      scrollWheelZoom: false,
      baseLayer: new maptalks.TileLayer('base', {
        urlTemplate:
          'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
        subdomains: ['a', 'b', 'c', 'd']
      })
    });
    const center = map.getCenter();
    const textMarkers = props.posts.edges
      .filter(({ node }) => node.location && node.location.lat)
      .map(({ node }) => {
        const marker = new maptalks.Marker(
          [node.location.lon, node.location.lat],
          {
            'properties': {
              'name': node.title
            },
            'symbol': {
              'textFaceName': 'sans-serif',
              'textName': '{name}', //value from name in geometry's properties
              'textSize': 24,
              'textFill': '#34495e',
              'textOpacity': 1,
              'textLineSpacing': 0,
              'textDx': 0,
              'textDy': -22,
              'textHorizontalAlignment': 'middle',
              'textVerticalAlignment': 'middle',
              'textAlign': 'center'
            }
          }
        );
        const textLayer = new maptalks.VectorLayer(`text-${node.title}`, marker);
        return {
          name: node.title,
          textLayer
        };
      })
      .reduce((acc, item) => {
        acc[item.name] = item.textLayer;
        return acc;
      }, {});

    const circles = props.posts.edges.map(({ node }) => {
      return new maptalks.Circle(
        [node.location.lon, node.location.lat],
        200000,
        {
          symbol: {
            lineColor: '#34495e',
            lineWidth: 2,
            polygonFill: '#DD1F2C',
            polygonOpacity: 0.4
          }
        }
      ).on('click', () => {
        const article = getArticleFromSlug(node.slug);
        setActiveArticle(article);
      }).on('mouseenter', () => {
        const textLayer = textMarkers[node.title];
        textLayer.addTo(map);
      }).on('mouseout', () => {
        const textLayer = textMarkers[node.title];
        map.removeLayer(textLayer);
      });
    });

    const mapLayer = new maptalks.VectorLayer('vector')
      .addGeometry(circles)
      .addTo(map);
  }, []);

  return (<div>
    <div id={mapId}></div>
    {activeArticle}
  </div>);
};
